import React, { Component } from 'react';
import GoToTop from '../Components/GoToTop';
import Contacte1 from '../Components/Contacte1';
import Bottom from '../Components/Bottom';

class Contacte extends Component {
    render() {
        return (
            <div>
                <Contacte1 />
                <Bottom />
                <GoToTop />
            </div>
        );
    }
}

export default Contacte;